import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick";

const ReviewsWrapper = styled.section`
  width: 100%;
  padding: 4rem 2rem;

  ${({theme}) => theme.media.tablet} {
    display: flex;
  }

  ${({theme}) => theme.media.laptop} {
    max-width: 1200px;
    margin: 0 auto;
    padding: 8rem 2rem;
  }

  ${({theme}) => theme.media.desktop} {
    max-width: 1400px;
    padding: 10rem 2rem;
  }

  ${({theme}) => theme.media.desktopXL} {
    padding: 15rem 2rem;
  }
`;

const TextBox = styled.div`
  width: 100%;
  padding: 1rem 0;

  ${({theme}) => theme.media.tablet} {
    width: 50%;
    padding: 1rem 2rem;
  }

  ${({theme}) => theme.media.tabletXL} {
    padding: 1rem 3rem;
  }

  ${({theme}) => theme.media.laptop} {
    padding: 1rem 3rem 1rem 4rem;
  }
`;

const TestimonialBox = styled.div`
  width: 100%;
  padding: 1rem 0;

  ${({theme}) => theme.media.tablet} {
    width: 50%;
    transform: translateY(20px);
  }

  ${({theme}) => theme.media.tabletXL} {
    padding: 1rem 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    transform: translateY(40px);
  }

  ${({theme}) => theme.media.desktop} {
    transform: translateY(60px);
  }
`;

const H2 = styled.h2`
  color: ${({theme}) => theme.colors.mainBlack};
  font-size: 1.4rem;
  line-height: 1.3;
  margin-bottom: 1rem;


  ${({theme}) => theme.media.tablet} {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 2.2rem;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 2.7rem;
  }

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }
`;

const Testimonial = styled.div`

`;

const TestimonialText = styled.p`
font-weight: 300;

span {
  font-size: 1.3rem;
  margin: 0 .3rem;
  font-weight: 700;
  color: ${({theme}) => theme.colors.mainBlue};

  ${({theme}) => theme.media.desktop} {
    font-size: 1.6rem;
  }
}

${({theme}) => theme.media.desktop} {
  font-size: 1.3rem;
}
`;

const TestimonialCompany = styled.p`
  padding: .5rem;
  margin-bottom: .5rem;
  font-weight: 700;

  ${({theme}) => theme.media.desktop} {
    font-size: 1.4rem;
  }
`;


const Reviews = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuerySec {
    site {
      siteMetadata {
        reviews {
          company
          id
          review
        }
      }
    }
  }
  `)
  
  return (
    <ReviewsWrapper>

      <TextBox>
        <H2 data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000">Co myślą <span>Nasi klienci</span> o naszej pracy</H2>
      </TextBox>

      <TestimonialBox>
        <Slider
          speed={500}
          // autoplay={true}
          autoplaySpeed={3000}
          slidesToShow={1}
          slidesToScroll={1}
          infinite={true}
          dots={true}
          pauseOnHover={true}
          pauseOnFocus={true}
        >

          {data.site.siteMetadata.reviews.map(review => (
            <Testimonial key={review.id}>
            <TestimonialText>
              <span>"</span>{review.review}<span>"</span>
            </TestimonialText>
            <TestimonialCompany>
              {review.company}
            </TestimonialCompany>
          </Testimonial>
          ))}

        </Slider>
      </TestimonialBox>

    </ReviewsWrapper>
  )
}

export default Reviews