import React from "react"
import styled from 'styled-components'

import backGround from '../../images/team01.jpg';

const AboutWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 50vh;
  padding: 6rem 1rem 1rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${backGround});
  background-position: center;
  background-size: cover;

  ${({theme}) => theme.media.laptop} {
    min-height: 70vh;
  }
`;

const TextBox = styled.div`
  width: 100%;

  ${({theme}) => theme.media.tablet} {
    width: 70%;
    padding: .5rem 2rem;
  }
  ${({theme}) => theme.media.tabletXL} {
    width: 65%;
    padding: 5rem 2rem 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    width: 60%;
    padding: 1rem 6rem 2rem;
  }

  ${({theme}) => theme.media.desktop} {
    width: 60%;
    padding: 4rem 15rem 2rem;
  }

  ${({theme}) => theme.media.desktopXL} {
    width: 50%;
    padding: 4rem 0 2rem 22rem;
  }
`;

const H1 = styled.h1`
  position: relative;
  color: ${({theme}) => theme.colors.mainWhite};
  padding: 1rem;
  font-size: calc(4.31818vw + 28.18182px);
  line-height: 1.3;

  ${({theme}) => theme.media.laptop} {
    line-height: 1.1;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 5rem;
  }

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 1rem;
    width: 5rem;
    height: 5px;
    background: ${({theme}) => theme.colors.mainYellow};
  }
`;

const TextP = styled.p`
  color: ${({theme}) => theme.colors.mainWhite};
  font-size: calc(.68182vw + 13.81818px);
  line-height: 1.5;
  font-weight: 500;
  padding: 2rem 1rem;

  ${({theme}) => theme.media.laptop} {
    padding: 2rem 4rem 2rem 1rem;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 1.2rem;
    padding-right: 4rem;
  }
`;


const HeaderAbout = () => {
  return (
    <AboutWrapper>
      <TextBox>
        <H1>Trochę o naszej firmie</H1>
        <TextP>Witamy w naszym miejscu. To tutaj tworzymy i doskonalimy nasz e-Pulpit.<br></br> Promuje ideę: „Nie musisz mieć, możesz korzystać”.</TextP>
      </TextBox>
    </AboutWrapper>
  )
}

export default HeaderAbout