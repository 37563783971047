import React from 'react'
import styled from 'styled-components'

const ExperienceWrapper = styled.section`
  width: 100%;
  background: ${({theme}) => theme.colors.mainBlue}
`;

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 4rem 2rem;

  ::after {
    position: absolute;
    content: '';
    bottom: -2rem;
    right: 0;
    height: 2rem;
    width: 60%;
    background: ${({theme}) => theme.colors.mainYellow};

    ${({theme}) => theme.media.laptop} {
      clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
      right: -2rem;
    }  
    ${({theme}) => theme.media.desktop} {
      right: -4rem;
    } 
  }

  ${({theme}) => theme.media.tablet} {
    padding: 4rem;
  }

  ${({theme}) => theme.media.tabletXL} {
    text-align: center;
    padding: 4rem 5rem;
  }

  ${({theme}) => theme.media.laptop} {
    max-width: 1200px;
    margin: 0 auto;
  }

  ${({theme}) => theme.media.desktop} {
    max-width: 1400px;
    padding: 5rem;
    margin: 0 auto;
  }

  ${({theme}) => theme.media.desktopXL} {
    padding: 10rem 5rem;
  }
`;

const H2 = styled.h2`
  color: ${({theme}) => theme.colors.mainBlack};
  font-size: 1.4rem;
  line-height: 1.3;


  ${({theme}) => theme.media.tablet} {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 2rem;
  }

  ${({theme}) => theme.media.desktop} {
    margin-bottom: 2rem;
  }
`;

const Text = styled.p`
  margin: .7rem 0;
  color: ${({theme}) => theme.colors.mainBlack};

  ${({theme}) => theme.media.laptop} {
    font-size: 1.2rem;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 1.4rem;
  }
`;


const Experience = () => {
  return (
    <ExperienceWrapper>
      <InnerWrapper>
        <H2 data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="700">Doświadczenie... coś co zdobywa sie z czasem.</H2>
        <Text data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="900">Posiadamy 15-letnie doświadczenie w informatyce, w tym 6 lat w obsłudze firm w sektorze finansowym, sektorze przemysłowym oraz administracji publicznej.</Text>
        <Text data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1100">Poparte jest wieloma certyfikatami i szkoleniami z zakresu rozwoju osobistego, informatyki, Cloud, Microsoft i środowiska Windows. Zapraszamy również do zakupu serwera VPS dla WordPressów w usłudze BluSerwer.pl</Text>
      </InnerWrapper>
    </ExperienceWrapper>
  )
}

export default Experience