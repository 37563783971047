import React from "react"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderAbout from '../components/aboutComponents/headerAbout'
import IdeaSection from '../components/aboutComponents/idea'
import Experience from '../components/aboutComponents/experience'
import Reviews from '../components/aboutComponents/reviews'


const AboutPage = () => {
 
  return (
    <>
      <SEO 
        title='O nas. Nasza idea: „Nie musisz mieć, możesz korzystać”.'
        description="Poznajmy się. Promujemy ideę: „Nie musisz mieć, możesz korzystać”. Posiadamy 15 lat doświadczenia w pracy z serwerami zdalnymi, zaufało nam setki firm."
      />

      <HeaderAbout />
      <IdeaSection />
      <Experience />
      <Reviews />

    </>
  )
}

export default AboutPage
