import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const IdeaWrapper = styled.section`
  position: relative;
  width: 100%;

  ${({theme}) => theme.media.laptop} {
    &::after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      width: 10%;
      background: ${({theme}) => theme.colors.mainBlue};
    }
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  padding: 2rem 0 0;

  ${({theme}) => theme.media.tabletXL} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    padding: 6rem 0;
  }

  ${({theme}) => theme.media.laptopXL} {
    padding: 6rem 0 3rem;
  }

  ${({theme}) => theme.media.desktopXL} {
    padding: 10rem 0 7rem 10rem;
  }
`;

const LeftBox = styled.div`
width: 100%;
padding: 2rem;

${({theme}) => theme.media.tablet} {
  width: 80%;
  padding: 2rem 4rem;
}

${({theme}) => theme.media.tabletXL} {
  width: 100%;
  padding: 2rem;
}

${({theme}) => theme.media.laptopXL} {
  width: 93%;
  margin: 0 0 0 auto;
  padding: 2rem 5rem;
}

${({theme}) => theme.media.desktop} {
  width: 80%;
  margin: 0 0 0 auto;
  padding: 2rem 5rem;
}
`;

const RightBox = styled.div`
  z-index: 10;
  // width: 100%;

  ${({theme}) => theme.media.tablet} {
    height: 30vh;

    img {
      height: 30vh !important;
    }
  }

  ${({theme}) => theme.media.tabletXL} {
    height: auto;
  }

  ${({theme}) => theme.media.laptop} {
    height: 55vh;

    img {
      height: 80% !important;
    }
  }

  ${({theme}) => theme.media.desktop} {
    height: 70vh;
  }

  ${({theme}) => theme.media.desktopXL} {
    height: 60vh;

    img {
      height: 55% !important;
    }
  }
`;

const H2 = styled.h2`
  color: ${({theme}) => theme.colors.mainBlack};
  font-size: calc(3.18182vw + 21.81818px);

  ${({theme}) => theme.media.tablet} {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 3rem;
  }
`;

const Text = styled.p`
  margin: 1rem 0;
  color: ${({theme}) => theme.colors.mainBlack};

  ${({theme}) => theme.media.desktop} {
    font-size: 1.4rem;
  }
`;


const IdeaSection = () => {
  const data = useStaticQuery(graphql`
    query {
      IdeaImg: file(relativePath: { eq: "idea.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <IdeaWrapper>
      <InnerWrapper>

        <LeftBox>
          <H2 data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000">Witamy</H2>
          <Text>Pomysł e-Pulpit24.pl zrodził się z zainteresowania i pasji. Dzięki wieloletniej pracy w firmach usługowych o wysokiej innowacyjności jesteśmy otwarci na nowe rozwiązania i na indywidualne potrzeby Twojej firmy</Text>
          <Text>Teraz współtworzymy lokalną „Chmurę” obliczeniową „Era bez komputera”, „Komputer tam gdzie Ty” czyli komputer dostępny z dowolnej lokalizacji z dowolnego urządzenia. Rozwiązanie do którego przekonało się już wiele firm, chcących pracować mobilnie, bezpiecznie i elastycznie z kilku lokalizacji, szybko, wydajnie bez zbędnych kosztów początkowych.</Text>
        </LeftBox>
        <RightBox>
          <Img fluid={data.IdeaImg.childImageSharp.fluid} alt="Idea powstania komputera w chmurze" />
        </RightBox>

      </InnerWrapper>
    </IdeaWrapper>
  )
}

export default IdeaSection